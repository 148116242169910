
import { defineComponent } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useWearablesStore } from '@/store/wearables.module';
export default defineComponent({
  setup() {
    const wearablesStore = useWearablesStore();

    onBeforeRouteLeave((to) => {
      if (to.name !== 'follow-up-activity-event-detail' && to.name !== 'follow-up-activity') {
        wearablesStore.resetWearablesSelectedDates();
      }
    });
  },
});
